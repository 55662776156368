'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        'invalid': 'Podaj prawidłowe dane.',
        'legalDate': 'Aby móc składać zamówienia w sklepie internetowym Jack Wolfskin, trzeba mieć ukończone 18 lat.',
        'maxlength': $.validator.format('Podaj maksymalnie {0) znaków.'),
        'minlength': $.validator.format('Podaj co najmniej {0) znaków.'),
        'rangelength': $.validator.format('Podaj co najmniej {0) znaków i maksymalnie {1} znak.'),
        'email': 'Podaj prawidłowy adres e-mail.',
        'url': 'Podaj prawidłowy adres URL.',
        'date': 'Podaj prawidłową datę.',
        'number': 'Podaj swój numer.',
        'digits': 'Podaj tylko cyfry.',
        'equalTo': 'Powtórz tę samą wartość.',
        'range': $.validator.format('Podaj wartość pomiędzy {0} a {1}.'),
        'max': $.validator.format('Podaj wartość mniejszą lub równą {0}.'),
        'min': $.validator.format('Podaj wartość większą lub równą {0}.'),
        'creditcard': 'Podaj prawidłowy numer karty kredytowej.',
        'invalidZip': 'Podaj prawidłowy kod pocztowy.',
        'couponCodeMistakenForGiftCard': 'Podaj ważny kod bonu lub kod promocyjny. Kartę podarunkową można wykorzystać na etapie realizacji zamówienia.',
        'invalidGiftCard': 'Podaj numer karty podarunkowej. Jeśli posiadasz kod bonu lub kod promocyjny, możesz go zrealizować w koszyku.',
        'streetMissingHouseNumber': 'Nie podano żadnego numeru domu, czy tak ma być?',
        'invalidPassword': 'Hasło musi zawierać min. 8 znaków, przynajmniej jeden znak specjalny, wielką literę i cyfrę.',
        'passwordsDontMatch': 'Hasła nie są takie same.',
        'selectReturnReason': 'Wybierz powód zwrotu, aby kontynuować'
    });
    return $;
}));
